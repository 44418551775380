import React from 'react';
import { Link } from 'gatsby';

import '../../styles/scss/style.scss';

export const VideoSection = () => {
  return (
    <div className="text-light py-5" style={{ minHeight: '100vh', background: 'rgba(26,32,44,1)' }}>
      <div className="container">
        <div className="feature-3 mt-5">
          <div className="feature__wrap">
            <div className="mx-auto text-center">
              <h1 className="mb-3 mx-auto" style={{ maxWidth: '800px', fontSize: '3rem' }}>
                Build with your own tailwind css UI library and unlock massive editor advantages
              </h1>
              <div className="mb-5 mx-auto">
                <Link href="https://windframe.devwares.com">
                  <button className="btn btn-success mx-auto rounded-lg">
                    {' '}
                    Start building for free{' '}
                  </button>
                </Link>
              </div>

              <div>
                <video
                  muted
                  loop
                  autoPlay
                  className="mx-auto w-100"
                  style={{ maxWidth: '920px' }}
                  controls
                >
                  <source src={require('../images/videoimport.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
