import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from '../../../components/AppContext';
import NavBar from '../../../components/NavBar/WindframeNavForm';
import { Footer } from '../../../components/Footer/WindframeFooter';
import { Hero } from '../../../components/ImportTemplate/Hero';
import { Cta } from '../../../components/ImportTemplate/Cta';
import { Supports } from '../../../components/ImportTemplate/Supports';
import { VideoSection } from '../../../components/ImportTemplate/VideoSection';
import { Upcoming } from '../../../components/ImportTemplate/Upcoming';
import "../../../styles/scss/style.scss"

const ImportTemplate = () => {
  return (
    <AppContext>
      <Helmet>
        <title>
          Windframe Template Import - Import and visually edit your tailwind css websites, templates
          and components
        </title>
        <meta
          name="title"
          content="Windframe Import Template - Import and visually edit your tailwind css websites, templates and components"
        />
        <meta
          name="description"
          content="Use windframe to easily import and visually edit any tailwind css website."
        />
        <meta
          property="og:title"
          content="Windframe Import Template - Import and visually edit your tailwind css websites, templates and components"
        />
        <meta
          property="og:description"
          content="Use windframe to easily import and visually edit any tailwind css website. "
        />
        <meta
          property="twitter:title"
          content="Windframe Import Template - Import and visually edit your tailwind css websites, templates and components"
        />
        <meta
          property="twitter:description"
          content="Use windframe to easily import and visually edit any tailwind css website. "
        />
        <link rel="canonical" href="https://windframe.dev/import/" />
        <meta property="og:url" content="https://windframe.dev/import/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
        <meta
          name="twitter:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            {/* <div className="w-full h-10 px-6 flex items-center justify-center bg-orange-500 text-white">
              <p className="text-xl">
                We are live on product hunt today 🚀 and would appreciate your support!
              </p> */}
              {/* <a href="https://www.producthunt.com/posts/windframe-2-0-2" className="">
                <img
                  src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c534.png"
                  alt="ad-banner"
                  className="rounded-full w-10 h-10"
                />
              </a> */}
              {/* <a
                href="https://www.producthunt.com/posts/windframe-2-0-2"
                className="px-3 text-white underline text-xl font-bold !underline"
                style={{ textDecoration: 'underline' }}
              >
                Support on Product Hunt
              </a>
              </div> */}
            <div className="container">
              <NavBar user={user} />
            </div>
            <Hero />
            <Upcoming />
            <VideoSection />
            <Supports topText="Import Html and Export to" />
            <Cta />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default ImportTemplate;
