import React from 'react';
import { Link } from 'gatsby';

import '../../styles/scss/style.scss';

export const Hero = () => {
  return (
    <div className="py-5" style={{ minHeight: 'calc(100vh - 85px)' }}>
      <div className="container">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__header">
              <h2 className="hero__lead ">
                Import and visually edit your tailwind css
                <br /> websites, templates and components
              </h2>
              <p className="hero__text">
                Use windframe to easily import and visually edit any tailwind css website. Take
                advantage of windframe's visual intuitive features to easily customize any tailwind
                css template and generate well structured code for your projects in different
                frameworks
              </p>
              <div className="hero__buttons mb-4">
                {process.env.NODE_ENV === 'production' && (
                  <Link
                    href="https://windframe.devwares.com/?import=true"
                    className="btn1 hero__btn btn__inverse mx-auto"
                  >
                    <span className="btn__text">Build a Form</span>
                  </Link>
                )}
                {process.env.NODE_ENV === 'development' && (
                  <Link
                    href="http://localhost:3000/?import=true"
                    className="btn1 hero__btn btn__inverse mx-auto"
                  >
                    <span className="btn__text">Import a template</span>
                  </Link>
                )}
              </div>
            </div>
            <div className="hero__image">
              <video
                autoPlay
                muted
                loop
                className="feature__img"
                style={{ border: '2px solid #ddd', borderRadius: '1rem', paddingTop: '0.3rem' }}
              >
                <source src={require('../images/formheader.mp4')} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
