import React from 'react';

import '../../styles/scss/style.scss';

export const Upcoming = () => {
  return (
    <div className="py-5" style={{ minHeight: '100vh' }}>
      <div className="container">
        <div className="hero mt-5">
          <h1
            className="text-center pb-8 mx-auto"
            style={{ maxWidth: '600px', fontSize: '4.5rem', paddingBottom: '3rem' }}
          >
            Visual Features <br /> to 10x Productivity!
          </h1>
          <h5 className="text-center mb-5 mx-auto" style={{ maxWidth: '700px' }}>
            We are creating a tool that helps you be more productive and efficient when building
            websites and webapps
          </h5>
          <h5 className="text-center mb-5 mx-auto" style={{ maxWidth: '700px' }}>
            Follow our public roadmap{' '}
            <a
              href="https://trello.com/b/3azYgbnv/windframe-public-product-roadmap"
              target="_blank" rel="noreferrer"
            >
              {' '}
              using this link.{' '}
            </a>{' '}
            Suggest new features and vote for your favorite features you would like to see on
            windframe
          </h5>

          <div className="feature__features1">
            <div className="wrapper">
              <h4 className="uptext"> 🖥 Realtime editing using an intuitive interface </h4>
              <p>  Import your websites and templates and edit them visually (Easily add, remove or edit anything from your templates or website using an intuitive interface and seeing how it looks in realtime)</p>
            </div>
            <div className="wrapper">
              <h4 className="uptext">👌Well structured code </h4>
              <p>
                Generate well structured and indented code for your websites and templates when exporting them using windframe.
              </p>
            </div>
            <div className="wrapper">
              <h4 className="uptext"> 🔌 Prebuilt Templates </h4>
              <p>
                Add upto 1000+ prebuilt components /templates from windframe to your website to improve the design of your website/template
              </p>
            </div>
            <div className="wrapper">
              <h4 className="uptext"> ✍️ Easily undo and redo changes</h4>
              <p>
                Go back and forth your changes or updates easily using an undo and redo button.
              </p>
            </div>
            <div className="wrapper">
              <h4 className="uptext"> 👀 Render in various screensizes and devices </h4>
              <p>
                Easily see how your website renders in various screen sizes and devices using the different screen sizes available on windframe
              </p>
            </div>
            <div className="wrapper">
              <h4 className="uptext"> 🧩 Segmented classes</h4>
              <p>
                See all your tailwind classes segmented in the classes box
                into responsive and normal classes, easily remove and add classes back
                without typing to see how they affect your website design
              </p>
            </div>
            <div className="wrapper">
              <h4 className="uptext">🤩 Easily prototype any idea </h4>
              <p>
                Play with your website visually. Prototype an idea and see how they look without
                switching back and forth from code editor to browser
              </p>
            </div>

            <div className="wrapper">
              <h4 className="uptext">  ⚡️Export to various frameworks </h4>
              <p>
                Go from having just HTML version of your website to exporting the React and Vuejs code for your template/website/component you import into windframe.
              </p>
            </div>
            <div className="wrapper">
              <h4 className="uptext"> ✨ Responsive specific classes </h4>
              <p>
                Use the responsive classes tab to set only specific responsive classes and see which responsive classes each applied to each element of your website.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
